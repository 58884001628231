import axios, { type AxiosInstance } from 'axios';
import { useToast } from 'vue-toastification';
import { usePrescriberStore } from '~/stores/prescriber.store';

let instance: AxiosInstance;

function initAxiosInstance() {
  if (instance !== undefined) {
    // If the instance is already defined, return
    return;
  }

  const baseURL = getBaseURL();

  instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(async (config) => {
    const router = useRouter();
    const controller = new AbortController();
    try {
      const token = await getAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    catch {
      controller.abort();
      useToast().info('Your login has expired. Please log in again.');
      await router.push('/login');
    }
    return {
      ...config,
      signal: controller.signal,
    };
  });

  instance.interceptors.response.use(
    (response) => {
      const toast = useToast();
      const message = response?.data?.message;
      if (message) toast.success(message);
      return response?.data;
    },
    (err) => {
      // const { message, error } = err?.response?.data as ErrorResponse;
      const message = err?.response?.data?.message;
      const error = err?.response?.data?.error ?? err.message;
      if (message) useToast().error(message);
      else useToast().error(error);
      return Promise.reject(error);
    },
  );
}

export const client = {
  async get<T>(url: string) {
    initAxiosInstance();
    return instance.get<T>(url);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post<T>(url: string, data: any) {
    initAxiosInstance();
    return instance.post<T>(url, data);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async patch<T>(url: string, data: any) {
    initAxiosInstance();
    return instance.patch<T>(url, data);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async put<T>(url: string, data: any) {
    initAxiosInstance();
    return instance.put<T>(url, data);
  },

  async delete<T>(url: string) {
    initAxiosInstance();
    return instance.delete<T>(url);
  },
};

export const getBaseURL = () => {
  const { public: env } = useRuntimeConfig();
  return `${env.API_URL}/prescriber/v1`;
};

const getAccessToken = async (): Promise<string> => {
  const { data } = await useSupabaseClient().auth.getSession();
  // Get access token from the session
  const accessToken = data?.session?.access_token;
  if (!accessToken) throw new Error('Access token could not be fetched');
  // Get user role from the session and set in the store
  const userRole = data?.session?.user?.app_metadata?.role;
  const userId = data?.session?.user?.app_metadata?.prescriber_id;
  const email = data?.session?.user?.email;
  usePrescriberStore().setUserRole(userRole);
  usePrescriberStore().setUserId(userId);
  usePrescriberStore().setEmail(email ?? '');
  return accessToken;
};
